import { Component } from '@angular/core';

@Component({
  selector: 'app-nav-menu',
  templateUrl: './nav-menu.component.html',
  styleUrls: ['./nav-menu.component.scss']
})
export class NavMenuComponent {
  isExpanded = false;
  isAdmin = true;
  navbarOpen = false;
  dropdowns = {
    membership: false,
    events: false,
    initiatives: false,
    fundraising: false,
    about: false,
    admin: false,
  };

  toggleNavbar() {
    this.navbarOpen = !this.navbarOpen;
  }

  toggleDropdown(menu: string) {
    this.dropdowns[menu] = !this.dropdowns[menu];
  }

  collapse() {
    this.isExpanded = false;
  }

  toggle() {
    this.isExpanded = !this.isExpanded;
  }
}
