<p-toast></p-toast>

<div class="card">
    <p-toolbar styleClass="p-mb-4">
        <ng-template pTemplate="left">
            <button pButton pRipple label="New" icon="pi pi-plus" class="p-button-success p-mr-2"
                (click)="openNew()"></button>
            <button pButton pRipple label="Delete" icon="pi pi-trash" class="p-button-danger"
                (click)="deleteSelectedEvents()" [disabled]="!selectedEvents || !selectedEvents.length"></button>
        </ng-template>
    </p-toolbar>

    <p-table #dt [value]="events.publicEvents" 
        [rows]="10" 
        [resizableColumns]="true"
        [paginator]="true" 
        [globalFilterFields]="['year','season','eventName','description','startDate','endDate']"
        [(selection)]="selectedEvents" 
        [rowHover]="true" 
        dataKey="publicEventId"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" 
        [showCurrentPageReport]="true">
        <ng-template pTemplate="caption">
            <div class="p-d-flex p-ai-center p-jc-between">
                <h5 class="p-m-0">Manage Events</h5>
                <span class="p-input-icon-left">
                    <i class="pi pi-search"></i>
                    <input pInputText type="text" (input)="dt.filterGlobal( $any($event.target).value, 'contains')" placeholder="Search..." />
                </span>
            </div>
        </ng-template>
        <ng-template pTemplate="header">
            <tr>
                <th style="width: 3rem">
                    <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                </th>
                <th pResizableColumn pSortableColumn="season">Season <p-sortIcon field="season"></p-sortIcon></th>
                <th pResizableColumn pSortableColumn="year">Year <p-sortIcon field="year"></p-sortIcon></th>
                <th pResizableColumn pSortableColumn="eventName">EventName <p-sortIcon field="eventName"></p-sortIcon></th>
                <th pResizableColumn pSortableColumn="htmlEventDescription">Description <p-sortIcon field="htmlEventDescription"></p-sortIcon></th>
                <th pResizableColumn pSortableColumn="eventStartDate">Start Date <p-sortIcon field="eventStartDate"></p-sortIcon></th>
                <th pResizableColumn pSortableColumn="eventEndDate">Start Date <p-sortIcon field="eventEndDate"></p-sortIcon></th>
                <th pResizableColumn pSortableColumn="isClosed">Is Closed <p-sortIcon field="isClosed"></p-sortIcon></th>
                <th></th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-event>
            <tr>
                <td>
                    <p-tableCheckbox [value]="event"></p-tableCheckbox>
                </td>
                <td>{{event.season === 0?'Spring' : 'Fall'}}</td>
                <td>{{event.year}}</td>
                <td>{{event.eventName}}</td>
                <td>{{event.htmlEventDescription}}</td>
                <td>{{event.eventStartDate | date:'dd-MMM-yyyy'}}</td>
                <td>{{event.eventEndDate | date:'dd-MMM-yyyy'}}</td>
                <td>{{event.isClosed}}</td>
                <!-- <td><p-rating [ngModel]="product.rating" [readonly]="true" [cancel]="false"></p-rating></td>
                <td><span [class]="'product-badge status-' + product.inventoryStatus.toLowerCase()">{{product.inventoryStatus}}</span></td> -->
                <td>
                    <button pButton pRipple icon="pi pi-pencil" class="p-button-rounded p-button-success p-mr-2" (click)="editEvent(event)"></button>
                    <button pButton pRipple icon="pi pi-trash" class="p-button-rounded p-button-warning" (click)="deleteEvent(event)"></button>
                </td>
            </tr>
        </ng-template>
        <ng-template pTemplate="summary">
            <div class="p-d-flex p-ai-center p-jc-between">
                In total there are {{events?.publicEvents ? events?.publicEvents?.length : 0 }} events.
            </div>
        </ng-template>
    </p-table>
</div>

<p-dialog [(visible)]="eventDialog" [style]="{width: '450px'}" header="Event Details" [modal]="true" styleClass="p-fluid">
    <ng-template pTemplate="content">
        <div class="p-field">
            <label for="name">Name</label>
            <input type="text" pInputText id="name" [(ngModel)]="selectedEvent.eventName" required autofocus />
            <small class="p-invalid" *ngIf="submitted && !selectedEvent.eventName">Event Name is required.</small>
        </div>
        <div class="p-field">
            <label for="year">Year</label>
            <input type="text" pInputText id="year" [(ngModel)]="selectedEvent.year" required autofocus />
            <small class="p-invalid" *ngIf="submitted && !selectedEvent.year">Event Year is required.</small>
        </div>
        <div class="p-field">
            <label for="season">Season</label>
            <p-dropdown 
                id="season" 
                [(ngModel)]="selectedEvent.season" 
                [options]="seasonOptions" 
                placeholder="Select Season" 
                required 
                autofocus>
            </p-dropdown>
            <small class="p-invalid" *ngIf="submitted && !selectedEvent.season">Event Season is required.</small>
        </div>
        <div class="p-field">
            <label for="description">Description</label>
            <textarea id="description" pInputTextarea [(ngModel)]="selectedEvent.htmlEventDescription" required rows="3" cols="20"></textarea>
        </div>
        <div class="p-field">
            <label for="startDate">Start Date</label>
            <p-calendar 
                id="startDate" 
                [(ngModel)]="selectedEvent.eventStartDate" 
                required 
                [showIcon]="true" 
                placeholder="Select a date" 
                dateFormat="mm/dd/yy"                 
                >
            </p-calendar>
            <small class="p-invalid" *ngIf="submitted && !selectedEvent.eventStartDate">Start Date is required.</small>
        </div>
        
        <div class="p-field">
            <label for="startDate">End Date</label>
            <p-calendar 
                id="endDate" 
                [(ngModel)]="selectedEvent.eventEndDate" 
                required 
                [showIcon]="true" 
                placeholder="Select a date" 
                dateFormat="mm/dd/yy"                 
                >
            </p-calendar>
            <small class="p-invalid" *ngIf="submitted && !selectedEvent.eventEndDate">End Date is required.</small>
        </div>  
        <div class="p-field">
            <label for="isClosed">Is Closed</label>
            <p-checkbox 
                id="isClosed" 
                [binary]="true"
                [(ngModel)]="selectedEvent.isClosed">
                               
            </p-checkbox>
            
        </div>  
    </ng-template>
    
    <ng-template pTemplate="footer">
        <button pButton pRipple label="Cancel" icon="pi pi-times" class="p-button-text" (click)="hideDialog()"></button>
        <button pButton pRipple label="Save" icon="pi pi-check" class="p-button-text" (click)="saveEvent()"></button>
    </ng-template>
</p-dialog>
<p-confirmDialog [style]="{width: '450px'}"></p-confirmDialog>
