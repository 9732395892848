import { Component } from '@angular/core';
import { AnnouncementDto } from '../web-api-client';
import { AnnouncementService } from 'src/services/announcment.service';

@Component({
  selector: 'app-announcements',
  templateUrl: './announcements.component.html',
  styleUrl: './announcements.component.css'
})
export class AnnouncementsComponent {
  public announcementsArr: AnnouncementDto[] = []; 

  constructor(private announcementService: AnnouncementService){}

  ngOnInit(): void {
    this.loadAnnouncements();
  }

  loadAnnouncements(): void {
    
    this.announcementService.getAnnouncments().subscribe({
      next: (data) => {
        this.announcementsArr = data; // Assign the data to the events variable
      },
      error: (err) => {
        console.error('Error fetching announcements:', err); // Handle error case
      }
    });
  }

}
