<div class="divAnnouncmentsMainContainer">
    <h3>Announcments</h3>
    <p>Please check the PTO bi-weekly communications for more details.</p>

    <div class="announcementContainer">
        <div class="announcment" *ngFor="let announcement of announcementsArr">
            <h4>{{ announcement.title }}</h4>
            <div>
                <p>
                    {{ announcement.htmlDescription }}
                </p>
            </div>
        </div>
    </div>

</div>