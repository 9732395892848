import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { ModalModule } from 'ngx-bootstrap/modal';

import { AppComponent } from './app.component';
import { NavMenuComponent } from './nav-menu/nav-menu.component';
import { HomeComponent } from './home/home.component';
import { CounterComponent } from './counter/counter.component';
import { FetchDataComponent } from './fetch-data/fetch-data.component';
import { TodoComponent } from './todo/todo.component';
import { AuthInterceptor } from 'src/api-authorization/authorize.interceptor';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LoginComponent } from './login/login.component';
import { AuthGuard } from 'src/RouteGuards/auth.guard';
import { MainLayoutComponent } from './app-main-layout/app-main-layout.component';
import { WelcomeComponent } from './welcome/welcome.component';
import { UpcomingEventsComponent } from './upcoming-events/upcoming-events.component';
import { AnnouncementsComponent } from './announcements/announcements.component';
import { EventListComponent } from './event-list/event-list.component';

import { TableModule } from 'primeng/table'; // Import the TableModule
import { ButtonModule } from 'primeng/button';
import { DropdownModule } from 'primeng/dropdown';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { Toolbar, ToolbarModule } from 'primeng/toolbar';
import { CalendarModule } from 'primeng/calendar';
import { DialogModule } from 'primeng/dialog';
import {ToastModule} from 'primeng/toast';
import {InputTextModule} from 'primeng/inputtext';
import {InputTextareaModule} from 'primeng/inputtextarea';
import {CheckboxModule} from 'primeng/checkbox';
import { ConfirmationService, MessageService } from 'primeng/api';


@NgModule({
  declarations: [
    AppComponent,
    NavMenuComponent,
    HomeComponent,
    CounterComponent,
    FetchDataComponent,
    TodoComponent,
    LoginComponent,
    MainLayoutComponent,
    WelcomeComponent,
    UpcomingEventsComponent,
    AnnouncementsComponent,
    EventListComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    ToastModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,  // Include ReactiveFormsModule for reactive forms
    TableModule,
    ButtonModule,
    DropdownModule,
    ToolbarModule,
    DialogModule,
    CalendarModule,
    InputTextModule,
    InputTextareaModule,
    ConfirmDialogModule,
    RouterModule.forRoot([
      { path: 'login', component: LoginComponent },
      {
        path: '',
        component: MainLayoutComponent,
        //canActivate: [AuthGuard], // Protect this layout with the AuthGuard
        children: [
          { path: '', component: HomeComponent },
          { path: 'counter', component: CounterComponent },
          { path: 'fetch-data', component: FetchDataComponent },
          { path: 'todo', component: TodoComponent },
          { path: 'eventlist', component: EventListComponent },
        ],
      },
      { path: '**', redirectTo: '/login' },
    ]),
    BrowserAnimationsModule,
    CheckboxModule,
    ModalModule.forRoot(),
    
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    MessageService,
    ConfirmationService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
