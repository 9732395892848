import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { PublicEventClient, CreatePublicEventCommand, PublicEventVm, PublicEventDto, ICreatePublicEventCommand, PublicEvent, UpdatePublicEventCommand, DeletePublicEventsCommand } from 'src/app/web-api-client';

@Injectable({
    providedIn: 'root'
})
export class PublicEventService {
    
    constructor(private publicEventClient: PublicEventClient) {}

    getAllPublicEvents() : Observable<PublicEventVm> {
        return this.publicEventClient.getPublicEvents();
    }

    createPublicEvent(publicEvent:PublicEventDto): Observable<PublicEvent>{
        const eventCommand = CreatePublicEventCommand.fromJS({
            season: publicEvent.season,
            year: publicEvent.year,
            eventName: publicEvent.eventName,
            htmlEventDescription: publicEvent.htmlEventDescription,
            imageUrl: "",
            eventStartDate: publicEvent.eventStartDate,
            eventEndDate: publicEvent.eventEndDate,
            isClosed: publicEvent.isClosed
        });
        
        return this.publicEventClient.createPublicEvent(eventCommand);
    }

    updatePublicEvent(publicEvent:PublicEventDto): Observable<PublicEvent>{
        const eventCommand = UpdatePublicEventCommand.fromJS({
            publicEventId: publicEvent.publicEventId,
            season: publicEvent.season,
            year: publicEvent.year,
            eventName: publicEvent.eventName,
            htmlEventDescription: publicEvent.htmlEventDescription,
            imageUrl: "",
            eventStartDate: publicEvent.eventStartDate,
            eventEndDate: publicEvent.eventEndDate,
            isClosed: publicEvent.isClosed
        });
        
        return this.publicEventClient.updatePublicEvent(eventCommand);
    }

    deletePublicEvents(publicEvents: PublicEventDto[]) : Observable<boolean> {
        const deleteCommand = DeletePublicEventsCommand.fromJS({
            publicEventsToDelete: publicEvents.map(event => ({
                publicEventId: event.publicEventId,
                season: event.season,
                year: event.year,
                eventName: event.eventName,
                htmlEventDescription: event.htmlEventDescription,
                imageUrl: event.imageUrl,
                eventStartDate: event.eventStartDate,
                eventEndDate: event.eventEndDate
            }))
        });

        return this.publicEventClient.deletePublicEvent(deleteCommand);
    }

}