import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { PublicEventClient, CreatePublicEventCommand, PublicEventVm, AnnouncmentClient, AnnouncementDto } from 'src/app/web-api-client';

@Injectable({
    providedIn: 'root'
})
export class AnnouncementService {
    
    constructor(private announcementClient: AnnouncmentClient) {}

    getAnnouncments() : Observable<AnnouncementDto[]> {
        return this.announcementClient.getAnnouncments();
    }

}