<div class="divEventMainContainer">
    <h3>PTO Events and Fundraisers</h3>
    <p>All dates are tentative and subject to change</p>

    <div class="eventContainer">
        <div class="events" *ngFor="let season of events.publicEventsBySeasons">
            <h4>{{ season.seasonYear }}</h4>
            <div *ngFor="let event of season.publicEvents">
                <br>
                <em style="color:#000000">{{ event.eventName }} </em>
                <strong style="color:#000000"><em>(8/16 Fri)</em></strong>
            </div>
        </div>
    </div>


    <!-- <ul>
        <li *ngFor="let event of events.publicEvents">
            {{ event.eventName }} ({{ event.eventStartDate }})
        </li>
    </ul> -->
</div>