<nav class="navbar">
    <div class="container">
        <button class="navbar-toggler" (click)="toggleNavbar()" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
        </button>
        <div class="navbar-collapse" [ngClass]="{ 'show': navbarOpen }">
            <ul class="navbar-nav">
                <li class="nav-item">
                    <a class="nav-link" href="#">Home</a>
                </li>
                <li class="nav-item dropdown">
                    <a class="nav-link dropdown-toggle" href="#" (click)="toggleDropdown('membership')">
                        Membership
                    </a>
                    <ul class="dropdown-menu" *ngIf="dropdowns.membership">
                        <!-- Submenu options will be populated by API call -->
                    </ul>
                </li>
                <li class="nav-item dropdown">
                    <a class="nav-link dropdown-toggle" href="#" (click)="toggleDropdown('events')">
                        Events
                    </a>
                    <ul class="dropdown-menu" *ngIf="dropdowns.events">
                        <!-- Submenu options will be populated by API call -->
                    </ul>
                </li>
                <li class="nav-item dropdown">
                    <a class="nav-link dropdown-toggle" href="#" (click)="toggleDropdown('initiatives')">
                        Initiatives
                    </a>
                    <ul class="dropdown-menu" *ngIf="dropdowns.initiatives">
                        <!-- Submenu options will be populated by API call -->
                    </ul>
                </li>
                <li class="nav-item dropdown">
                    <a class="nav-link dropdown-toggle" href="#" (click)="toggleDropdown('fundraising')">
                        Fundraising
                    </a>
                    <ul class="dropdown-menu" *ngIf="dropdowns.fundraising">
                        <!-- Submenu options will be populated by API call -->
                    </ul>
                </li>
                <li class="nav-item">
                    <a class="nav-link" href="#">Sponsorship</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" href="#">Volunteer</a>
                </li>
                <li class="nav-item dropdown">
                    <a class="nav-link dropdown-toggle" href="#" (click)="toggleDropdown('about')">
                        About
                    </a>
                    <ul class="dropdown-menu" *ngIf="dropdowns.about">
                        <li><a class="dropdown-item" href="#">PTO team</a></li>
                        <li><a class="dropdown-item" href="#">ByLaws</a></li>
                        <li><a class="dropdown-item" href="#">Meeting and Minutes</a></li>
                        <li><a class="dropdown-item" href="#">Finances</a></li>
                    </ul>
                </li>
                <li class="nav-item">
                    <a class="nav-link" href="#">Gallery</a>
                </li>
                <li class="nav-item dropdown" *ngIf="isAdmin">
                    <a class="nav-link dropdown-toggle" href="#" (click)="toggleDropdown('admin')">
                        Admin
                    </a>
                    <ul class="dropdown-menu" *ngIf="dropdowns.admin">
                        <li><a class="dropdown-item" [routerLink]="['/eventlist']">Manage Events</a></li>
                        <li><a class="dropdown-item" href="#">Manage Announcements</a></li>
                    </ul>
                </li>
            </ul>
        </div>
    </div>
</nav>
